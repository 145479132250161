
import { v4 as uuidv4 } from 'uuid';

export default {
  name: 'SiteWideNotification',

  mounted() {
    this.$nuxt.$on('notification', this.openNotification);
    this.$nuxt.$on('closeNotificationBar', this.close);
  },

  beforeDestroy() {
    this.$nuxt.$off('notification');
    this.$nuxt.$off('closeNotificationBar');
  },

  data() {
    return {
      notifications: [],
    };
  },

  methods: {
    openNotification(text, type = 'alert', autoClose = true, timeout = 5000, data = null, title = null, uuid = null) {
      if (!uuid) {
        uuid = uuidv4();
      }

      if (type === 'auto_save') {
        this.notifications = this.notifications.filter((item) => {
          return item.type !== 'auto_save';
        });
      }

      this.notifications.push({
        text: text,
        type: type,
        uuid: uuid,
        timeout: timeout,
        data: data,
        title: title,
      });

      if (autoClose) {
        this.close(uuid, timeout);
      }
    },

    close(uuid, timeout) {
      setTimeout(() => {
        this.removeMe(uuid);
      }, timeout);
    },

    removeMe(uuid) {
      this.notifications = this.notifications.filter((item) => {
        return item.uuid !== uuid;
      });
    },
  },
};
