
import checkForErrors from '@/helpers/checkForErrors';
import tasksApi from '@/api/tasks';

export default {
  name: 'PlanTaskSelect',

  props: {
    value: {
      type: String | null,
      required: true,
    },
    plan_uuid: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: false,
    },
    isRequired: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      loading: true,
      tasks: [],
    };
  },

  computed: {
    selectedList: {
      get: function () {
        if (this.value) {
          return this.tasks.filter((item) => this.value.includes(item.uuid));
        }
      },
      set: function (newVal) {
        if (newVal) {
          this.$emit('input', newVal.uuid);
        } else {
          this.$emit('input', null);
        }
      },
    },
  },

  mounted() {
    this.getTasks(this.plan_uuid);
  },

  methods: {
    getTasks(plan_uuid) {
      this.loading = true;
      tasksApi
        .getAllTasksFromFirm({
          'filter[taskable_uuid]': plan_uuid,
          per_page: 250,
        })
        .then((rsp) => {
          this.tasks = rsp;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          this.$nuxt.$emit('notification', err.response.data.message, 'warning', true);
        });
    },
  },

  watch: {
    plan_uuid(new_val) {
      this.getTasks(new_val);
    },
  },
};
