import { render, staticRenderFns } from "./CreateTask.vue?vue&type=template&id=22709536"
import script from "./CreateTask.vue?vue&type=script&lang=js"
export * from "./CreateTask.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ErrorBlock: require('/vercel/path0/components/ErrorBlock.vue').default,RichTextEditor: require('/vercel/path0/components/RichTextEditor.vue').default,AccountSelector: require('/vercel/path0/components/AccountSelector.vue').default,StyledSelect: require('/vercel/path0/components/StyledSelect.vue').default,AccountSelector: require('/vercel/path0/components/AccountSelector.vue').default,ClientCaseSelector: require('/vercel/path0/components/ClientCaseSelector.vue').default,ClientPlanSelect: require('/vercel/path0/components/ClientPlanSelect.vue').default,CustomField: require('/vercel/path0/components/CustomField.vue').default})
