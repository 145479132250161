
import { debounce } from 'lodash';
export default {
  name: 'CustomField',
  props: {
    value: {
      required: true,
      type: String | Number | Boolean,
    },
    field: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      allowBubble: true,
    };
  },

  computed: {
    getDate: {
      get() {
        return this.$nuxt.$helpers.formatDate(this.value);
      },
      set(value) {
        if (this.isValidDate(value)) {
          this.bubbleUp(value);
        }
      },
    },
    bool: {
      get() {
        return this.value;
      },
      set(value) {
        this.bubbleUp(value);
      },
    },
  },

  methods: {
    isValidDate(d) {
      return new Date(d).toString() !== 'Invalid Date';
    },

    debounceFunction: debounce(function (data) {
      this.$emit('input', data);
      this.allowBubble = true;
    }, 1500),

    keyup(data) {
      //disable bubbleUp here to prevent datetime reseting as typing when it bubbles up
      this.allowBubble = false;
      this.debounceFunction(data);
    },
    bubbleUp(data) {
      if (this.allowBubble) {
        this.$emit('input', data);
      }
    },
  },
};
